import http from '@/http-common'

const URL = '/api'

class DataService {
  getData() {
    return http.get(`${URL}/get-store-data`)
  }
  getJobData() {
    return http.get(`${URL}/get-job-data`)
  }
  getExportCenterData(data) {
    return http.get(`${URL}/get-export-center-data?pol=${data.port_of_loading_id ? data.port_of_loading_id : ''}
                            &pod=${data.port_of_discharge_id ? data.port_of_discharge_id : ''}
                            &office=${data.office_id ? data.office_id : ''}
                            &customer=${data.customer_id ? data.customer_id : ''}`)
  }
}

export default new DataService()