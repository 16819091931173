import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import http from '@/http-common'

export default createStore({
  state: {
    token: null,
    token_expiration: null,
    user: {
      name: null,
      id: null,
      email: null,
      role: null,
      profile_image: null,
      office_id: null,
      company_hq: null,
      company_name: null,
      company_location: '',
      documentation_clearance: null,
      user_group: '',
      user_ports: [],
      can_finalize_own_bls: false,
      invoice_rights: 'none'
    }
  },
  plugins: [createPersistedState({
    "key": "user"
  })],
  mutations: {
    LOGIN_SUCCESS(state, userData) {
      state.token = userData.token
      state.token_expiration = userData.token_expiration
      state.user.id = userData.id
      state.user.name = userData.name
      state.user.email = userData.email
      state.user.role = userData.role,
      state.user.profile_image = userData.profile_image,
      state.user.office_id = userData.office_id,
      state.user.company_hq = userData.company_hq,
      state.user.company_name = userData.office_name,
      state.user.company_location = userData.office_location,
      state.user.documentation_clearance = userData.documentation_clearance,
      state.user.user_group = userData.user_group,
      state.user.user_ports = userData.user_ports,
      state.user.can_finalize_own_bls = userData.can_finalize_own_bls,
      state.user.invoice_rights = userData.invoice_rights
    },
    LOGIN_SESSION_SUCCESS(state, userData) {
      state.user.name = userData.name
      state.user.email = userData.email
      state.user.role = userData.role,
      state.user.profile_image = userData.profile_image,
      state.user.office_id = userData.office_id,
      state.user.company_hq = userData.company_hq,
      state.user.company_name = userData.office_name,
      state.user.company_location = userData.office_location,
      state.user.documentation_clearance = userData.documentation_clearance,
      state.user.user_group = userData.user_group,
      state.user.user_ports = userData.user_ports,
      state.user.can_finalize_own_bls = userData.can_finalize_own_bls,
      state.user.invoice_rights = userData.invoice_rights
    },
    LOGOUT_SUCCESS(state) {
      state.token = null
      state.token_expiration = null
      state.user = {
        id: null,
        name: null,
        email: null,
        role: null,
        profile_image: null,
        office_id: null,
        company_hq: null,
        company_name: null,
        company_location: '',
        documentation_clearance: null,
        user_group: '',
        user_ports: [],
        can_finalize_own_bls: false,
        invoice_rights: 'none'
      }
    }
  },
  actions: {
    userLogin({getters, commit}, response) {
      commit('LOGIN_SUCCESS', response)
      http.defaults.headers.common['Authorization'] = getters.getAuthHeader
    },
    userSessionLogin({getters, commit}, response) {
      commit('LOGIN_SESSION_SUCCESS', response)
    },
    userLogout({commit}, response) {
      commit('LOGOUT_SUCCESS', response)
      sessionStorage.clear();
      http.defaults.headers.common['Authorization'] = ''
    }
  },
  modules: {
  },
  getters: {
    getAuthHeader: state => {
      if (state.token != null) {
        return 'Bearer ' + state.token
      } else {
        return ''
      }
    },
    isLoggedIn: state => {
      if (state.user.name != null) {
        return true
      } else {
        return false
      }
    },
    getName: state => {
        return state.user.name
    },
    getEmail: state => {
      return state.user.email
    },
    getCompanyId: state => {
      return state.user.office_id
    },
    getCompanyHq: state => {
      return state.user.company_hq
    },
    getCompanyName: state => {
      return state.user.company_name
    },
    getCompanyLocation: state => {
      return state.user.company_location
    },
    getDocumentationClearance: state => {
      return state.user.documentation_clearance
    },
    getProfileImage: state => {
        return state.user.profile_image
    },
    userId: state => {
        return state.user.id
    },
    getUser: state => {
      return state.user
    },
    isGlobalAdmin: state => {
      return state.user.user_group == 'Administrator'
    },
    isAdmin: state => {
      return state.user.user_group == 'Administrator' || state.user.user_group == 'Office Admin'
    },
    isUser: state => {
      return state.user.user_group == 'User'
    },
    isAdminOrUser: state => {
      return state.user.user_group == 'Administrator' || state.user.user_group == 'Office Admin' || state.user.user_group == 'User'
    },
    isCustomer: state => {
      return state.user.user_group == 'Customer'
    },
    isSurveyor: state => {
      return state.user.user_group == 'Surveyor'
    },
    isSurveyorOrPortOperator: state => {
      return state.user.user_group == 'Surveyor' || state.user.user_group == 'Port operator'
    },
    canFinalizeOwnBls: state => {
      return (state.user.user_group == 'Administrator' || state.user.user_group == 'Office Admin') || (state.user.user_group == 'User' && state.user.can_finalize_own_bls)
    },
    canViewInvoices: state => {
      return (state.user.user_group == 'Administrator' || state.user.user_group == 'Office Admin') || (state.user.user_group == 'User' && state.user.invoice_rights != 'none')
    },
    canEditInvoices: state => {
      return (state.user.user_group == 'Administrator' || state.user.user_group == 'Office Admin') || (state.user.user_group == 'User' && state.user.invoice_rights == 'full_access')
    },
    getSurveyorPorts: state => {
      return state.user.user_ports
    }
  }
})
